<template>
  <head-top :showBackArrow="false" :title="titleName">
    <div class="activity">
      <div class="head-img">
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="(image, index) in images" :key="index">
            <img v-lazy="image" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="activity-content">
        <!-- <formItem :formList="form"></formItem> -->
        <div class="formItem">
          <div class="title-dev">
            <p class="title">主办方：</p>
            <p class="content">{{ list.company }}</p>
          </div>
        </div>
        <div class="formItem">
          <div class="title-dev">
            <p class="title">活动时间：</p>
            <p class="content">{{ list.time }}</p>
          </div>
        </div>
        <div class="formItem">
          <div class="title-dev">
            <p class="title">活动地址：</p>
            <p class="content">{{ list.address }}</p>
          </div>
        </div>
        <div class="formItem">
          <div class="title-dev">
            <p class="title">会议室：</p>
            <p class="content">{{ list.conferenceRoomName }}</p>
          </div>
        </div>
        <div class="formItem" v-if="list.introduction.length > 0">
          <div class="title-dev">
            <p class="title">活动简介：</p>
            <p
              class="content"
              v-for="(item, index) in list.introduction"
              :key="index"
            >
              {{ item }}
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="participate-content">
        <div class="title-dev">
          <p class="title">参会人员（{{ personnel.length }}人）</p>
          <div
            class="renyuan"
            v-for="(item, index) in personnel"
            v-if="index < 6"
            :key="index"
          >
            <img :src="item.avatarUrl" alt="" />
            <span>{{ item.name }}</span>
          </div>
          <div class="renyuan" v-if="personnel.length > 6">
            <span class="gengduo" @click="clickPopup">更多</span>
            <span></span>
          </div>
        </div>
      </div>-->
      <div class="summary" v-if="list.process.length > 0">
        <div class="title-dev">
          <p class="summary-title">活动流程</p>
          <!-- <p class="content">{{ list.process }}</p> -->
          <div class="summary-content">
            <div v-for="(item, index) in list.process" :key="index">
              {{ item }}
            </div>
          </div>
        </div>
      </div>

      <div class="summary">
        <div class="summary-title">酒店名称:</div>
        <div class="summary-content">{{ list.hotelName }}</div>
      </div>
      <div class="summary">
        <div class="summary-title">活动接待人:</div>
        <div class="summary-content">{{ list.contactName }}</div>
      </div>
      <div class="summary">
        <div class="summary-title">接待电话:</div>
        <div class="summary-content">{{ list.contactPhone }}</div>
      </div>
      <!-- <div class="summary">
        <div class="summary-title">活动地址:</div>
        <div class="summary-content">{{ list.address }}</div>
      </div>-->
      <div class="summary" v-if="list.trafficGuidance.length > 0">
        <div class="summary-title">交通指引:</div>
        <div class="summary-content">
          <div v-for="(item, index) in list.trafficGuidance" :key="index">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="tosignUp">
        <div v-if="list.isOverdue == 0" class="tosignUp">
          <van-button
            v-if="list.applyStatus == 1"
            type="info"
            style="background-color: #333; border: 0.026666rem solid #333"
            >已报名</van-button
          >
          <!-- <van-button v-else type="primary" @click="signUp"
            >我要报名</van-button
          >-->
          <van-button type="info" @click="reservation">我要订房</van-button>
        </div>
        <div v-else class="tosignUp">
          <!-- <van-button
            type="info"
            style="background-color: #333; border: 0.026666rem solid #333"
            >活动已过期</van-button
          >-->
          <van-button type="info" @click="reservation">我要订房</van-button>
        </div>
      </div>
      <van-popup v-model="show">
        <van-form @submit="confirmUse">
          <van-field
            v-model="name"
            name="姓名"
            label="姓名"
            placeholder="姓名"
          />
          <van-field
            v-model="phone"
            type="phone"
            name="手机号码"
            label="手机号码"
            placeholder="手机号码"
          />
          <div class="form-div">
            <van-button round block type="info" native-type="submit"
              >提交</van-button
            >
          </div>
        </van-form>
      </van-popup>
      <div class="right-content" @click="orderClcik">订单中心</div>
      <div class="tangchuzhe" v-if="isQrcode">
        <div class="tangchuzhe-content">
          <img src="../../assets/qrcode.jpg" alt />
          <div class="wenzi">请长按上方二维码关注公众号</div>
        </div>
      </div>
    </div>
    <van-popup v-model="isShow" position="right" class="tankuang">
      <div class="tangContent">
        <div class="renyuan" v-for="(item, index) in personnel" :key="index">
          <img :src="item.avatarUrl" alt />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </van-popup>
  </head-top>
</template>

<script>
import wx from "weixin-js-sdk";
import formItem from "../../components/formItem.vue";
import headTop from "../../components/head.vue";
import {
  authorize,
  activityDetail,
  activityApply,
  isFocuson,
} from "../../api/home/index.js";

import { getQueryVariable } from "../../utils/utils";

export default {
  data() {
    return {
      isQrcode: false,
      isShow: false,
      name: "", //姓名
      phone: "", //手机号码
      show: false,
      list: {
        introduction: [],
        process: [],
        trafficGuidance: [],
      },
      desc: "", //描述
      activityId: "", //活动 id
      images: [],
      personnel: [],
      _interval: "",
      titleName: "",
    };
  },
  components: {
    formItem,
    headTop,
  },
  watch: {
    titleName(val) {
      console.log(val, this.$route, "valvalval");
      this.$route.meta.title = val;
    },
  },
  mounted() {
    if (!window.sessionStorage.getItem("token")) {
      this.$router.push({
        path: "/",
        query: {
          a: this.$route.query.a,
        },
      });
    } else {
      console.log(window.sessionStorage.getItem("token"), "token测试时");
      // this.activityId = this.$route.query.a;
      window.sessionStorage.setItem("activityId", this.$route.query.a);
      this.isFocuson();
    }
  },

  methods: {
    share() {
      let url = window.location.origin;
      let dizhi = `https://book.easelandhotel.com/home?a=${this.$route.query.a}`;
      let urlImg =
        this.list.pics.length > 0
          ? this.list.pics[0]
          : `${url}/files/2021/09/25/logo.png`;
      let _this = this;
      wx.onMenuShareAppMessage &&
        wx.onMenuShareAppMessage({
          title: _this.list.name, // 分享标题
          desc: _this.desc,
          link: dizhi, // `${url}/api/wechat/authorize?a=${_this.activityId}`,
          imgUrl: urlImg, // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
            console.log("分享成功onMenuShareAppMessage");
          },
          cancel: function () {
            console.log("分享到朋友失败onMenuShareAppMessage");
          },
        });

      wx.onMenuShareTimeline &&
        wx.onMenuShareTimeline({
          title: _this.list.name, // 分享标题
          desc: _this.desc,
          link: dizhi, // `${url}/api/wechat/authorize?a=${_this.activityId}`,
          imgUrl: urlImg, // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
            console.log("分享成功onMenuShareTimeline");
          },
          cancel: function () {
            console.log("分享到朋友失败onMenuShareTimeline");
          },
        });

      wx.updateAppMessageShareData &&
        wx.updateAppMessageShareData({
          title: _this.list.name, // 分享标题
          desc: _this.desc,
          link: dizhi, // `${url}/api/wechat/authorize?a=${_this.activityId}`,
          imgUrl: urlImg, // 分享图标
          success: async (res) => {
            console.log("updateAppMessageShareData成功");
          },
        });

      wx.updateTimelineShareData &&
        wx.updateTimelineShareData({
          title: _this.list.name, // 分享标题
          desc: _this.desc,
          link: dizhi, // `${url}/api/wechat/authorize?a=${_this.activityId}`,
          imgUrl: urlImg, // 分享图标
          success: async (res) => {
            console.log("updateTimelineShareData成功");
          },
        });
    },
    clickPopup() {
      this.isShow = true;
    },
    // 判断是否关注公众号
    async isFocuson() {
      let res = await isFocuson();
      if (res.code == 200) {
        if (res.data == 0) {
          this.isQrcode = true;
          window.sessionStorage.setItem("isQrcode", true);
          let that = this;
          that._interval = setTimeout(() => {
            that.isFocuson();
          }, 1000);
        } else {
          window.sessionStorage.removeItem("isQrcode");
          clearInterval(this._interval);
          this.isQrcode = false;
          this.activityDetail();
        }
      }
    },
    // 点击到订单中心
    orderClcik() {
      this.$router.push({
        name: "payList",
      });
    },
    // 授权
    // async authorize() {
    //   let data = {
    //     activityId: this.$route.query.a,
    //   };
    //   let res = await authorize(data);
    //   if (res.code == 200) {
    //   }
    // },
    // 获取活动详情
    async activityDetail() {
      let data = {
        activityId: this.$route.query.a,
      };
      let res = await activityDetail(data);
      if (res.code == 200) {
        if (res.data.trafficGuidance) {
          res.data.trafficGuidance = res.data.trafficGuidance.replace(
            "\n",
            "||"
          );
          res.data.trafficGuidance = res.data.trafficGuidance.replace(
            "\r",
            "||"
          );
          res.data.trafficGuidance = res.data.trafficGuidance.replace(
            "\r\n",
            "||"
          );
          res.data.trafficGuidance = res.data.trafficGuidance.split("||");
        }
        this.desc = res.data.introduction;
        if (res.data.introduction) {
          res.data.introduction = res.data.introduction.replace("\n", "||");
          res.data.introduction = res.data.introduction.replace("\r", "||");
          res.data.introduction = res.data.introduction.replace("\r\n", "||");
          res.data.introduction = res.data.introduction.split("||");
        }
        if (res.data.process) {
          res.data.process = res.data.process.replace("\n", "||");
          res.data.process = res.data.process.replace("\r", "||");
          res.data.process = res.data.process.replace("\r\n", "||");
          res.data.process = res.data.process.split("||");
        }
        this.list = res.data;
        this.titleName = this.list.name;
        this.personnel = res.data.peopleList;
        this.images = res.data.pics;
        this.share();
      }
    },
    // 我要报名
    signUp() {
      this.show = true;
    },
    // 确认报名
    async confirmUse() {
      if (!this.name) {
        this.$toast("请填写姓名");
        return;
      }
      if (!this.phone) {
        this.$toast("请填写手机号码");
        return;
      }
      let phone =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!phone.test(this.phone)) {
        this.$toast("请填写正确的手机号码");
        return;
      }
      let data = {
        activityId: this.list.activityId,
        name: this.name,
        phone: this.phone,
      };
      let res = await activityApply(data);
      if (res.code == 200) {
        this.$router.push({
          name: "list",
          query: {
            activityId: this.list.activityId,
          },
          params: {
            successful: true,
          },
        });
      }
    },
    // 我要订房
    reservation() {
      this.$router.push({
        name: "list",
        query: {
          activityId: this.list.activityId,
        },
      });
    },
    // 取消报名
    cancelShow() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tankuang {
  height: 100%;
  width: 70%;

  .tangContent {
    padding: 20px 20px;
    text-align: left;
    .renyuan {
      margin: 0 auto;
      width: 95px;
      font-size: 28px;
      color: #333;
      line-height: 18px;
      display: inline-block;
      margin-right: 25px;
      text-align: center;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 30px;
      }
      span {
        color: #000;
      }
    }
  }
}
.activity {
  position: relative;
  padding-bottom: 100px;
  .head-img {
    height: 400px;
    width: 100%;
    img {
      height: 400px;
      width: 100%;
    }
  }
  .activity-content {
    // margin-top: 20px;
  }
  .participate-content {
    .title-dev {
      background-color: #fff;
      padding: 5px 10px;
      margin-bottom: 10px;
      .title {
        font-size: 30px;
        font-weight: 600;
        line-height: 22px;
        color: #000;
      }
      .renyuan {
        width: 96px;
        font-size: 24px;
        color: #333;
        line-height: 18px;
        display: inline-block;
        margin-right: 10px;
        text-align: center;
        img {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          margin-bottom: 10px;
        }
        .gengduo {
          display: inline-block;
          width: 90px;
          height: 90px;
          font-size: 20px;
          border-radius: 50%;
          margin-bottom: 10px;
          color: #fff;
          line-height: 90px;
          background: #1989fa;
          float: right;
        }
        span {
          color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          display: inline-block;
          height: 26px;
          line-height: 26px;
        }
      }
    }
  }
  .process {
    .title-dev {
      background-color: #fff;
      padding: 5px 10px;
      margin-bottom: 10px;
      .title {
        font-size: 30px;
        font-weight: 600;
        line-height: 22px;
        color: #000;
      }
      .content {
        font-size: 28px;
        color: #333;
        // line-height: 1;
      }
    }
  }
  .summary {
    display: flex;
    align-items: baseline;
    padding: 10px 10px;
    background-color: #fff;
    .summary-title {
      width: 180px;
      font-size: 30px;
      font-weight: 600;
      color: #333;
      text-align: left;
    }
    .summary-content {
      font-size: 28px;
      color: #333;
      flex: 1;
      div {
        line-height: 2;
      }
    }
  }
  .tosignUp {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    button {
      height: 80px;
      flex: 1;
      margin: 0 10px;
    }
  }
  /deep/.van-popup {
    padding: 20px;
    width: 500px;
    padding-top: 40px;
    border-radius: 10px;
    .form-div {
      margin-top: 20px;
    }
  }
  /deep/.van-field__label {
    width: 130px;
  }
  .formItem {
    .title-dev {
      background-color: #fff;
      padding: 5px 10px;
      margin-bottom: 10px;
      .title {
        font-size: 30px;
        font-weight: 600;
        line-height: 22px;
        color: #333;
      }
      .content {
        font-size: 28px;
        color: #333;
        // line-height: 18px;
      }
    }
  }
  .right-content {
    position: fixed;
    right: 0;
    top: 700px;
    width: 130px;
    background-color: rgba(61, 67, 67, 1);
    height: 42px;
    border-radius: 18px 0 0 18px;
    font-size: 24px;
    color: rgba(164, 140, 108, 1);
    text-align: center;
    line-height: 42px;
  }
  .tangchuzhe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    .tangchuzhe-content {
      // padding: 40px;
      position: fixed;
      top: 50%;
      left: 50%;
      width: 500px;
      height: 440px;
      transform: translate(-250px, -200px);
      margin: 0 auto;
      background: #fff;
      text-align: center;
      img {
        padding-top: 40px;
        width: 300px;
        height: 300px;
        margin: 0 auto;
      }
      .wenzi {
        padding: 0 10px;
        font-size: 30px;
        color: #333;
      }
    }
  }
}
</style>