<template>
  <div class="formItem">
    <div class="title-dev" v-for="(item, index) in formList" :key="index">
      <p class="title">{{ item.title }}</p>
      <p class="content">{{ item.content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.formItem {
  .title-dev {
    background-color: #fff;
    padding: 5px 10px;
    margin-bottom: 10px;
    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 22px;
      color: #333;
    }
    .content {
      font-size: 20px;
      color: #ccc;
      line-height: 18px;
    }
  }
}
</style>